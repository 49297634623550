import * as React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  ImageField,
  EditButton,
  TextInput,
  ImageInput,
  useRecordContext,
  required,
  BooleanInput
} from 'react-admin';
import BookIcon from '@mui/icons-material/Book';

export const PostIcon = BookIcon;

const ImageUrl = () => {
  const record = useRecordContext();
  return <img id="logo" src={`https://cdn.memed.com.br/resources/img/drugs/${record ? record.seller_id : ''}.png`}
              alt={`${record ? record.seller_id : ''}.png`}/>
}

export const SellerList = () => (
  <List>
    <Datagrid>
      <ImageUrl label="Seller Image"/>
      <TextField source="seller_id"/>
      <TextField source="brand_name"/>
      <EditButton/>
    </Datagrid>
  </List>
);

const SellerTitle = () => {
  const record = useRecordContext();
  return <span>Seller {record ? record.seller_id : ''}</span>;
};

export const SellerEdit = () => (
  <Edit title={<SellerTitle/>}>
    <SimpleForm sx={{'& div': {width: 400}}}>
      <TextInput disabled source="seller_id"/>
      <ImageInput source="file" accept="image/png">
        <ImageField source="src"/>
      </ImageInput>
      <TextInput source="brand_name"/>
      <TextInput source="fulfillment_endpoint"/>
      <TextInput source="corporate_name"/>
      <TextInput source="cnpj"/>
      <TextInput source="afe"/>
      <TextInput source="crf"/>
      <TextInput source="about" multiline/>
      <TextInput source="technical_contact"/>
      <TextInput source="heartbeat.zipcode"/>
      <TextInput source="heartbeat.skus"/>
      <BooleanInput source="specialControl"/>
      <BooleanInput source="pbm"/>
    </SimpleForm>
  </Edit>
);


export const SellerCreate = () => (
  <Create title="Criar um novo seller">
    <SimpleForm sx={{'& div': {width: 400}}}>
      <TextInput label="seller_id" source="seller_id" validate={required()}/>
      <ImageInput source="file" accept="image/png">
        <ImageField source="src"/>
      </ImageInput>
      <TextInput source="brand_name" validate={required()}/>
      <TextInput source="fulfillment_endpoint"/>
      <TextInput source="corporate_name" validate={required()}/>
      <TextInput source="cnpj"/>
      <TextInput source="afe" validate={required()}/>
      <TextInput source="crf" validate={required()}/>
      <TextInput source="about" validate={required()} multiline/>
      <TextInput source="technical_contact" validate={required()}/>
      <TextInput source="heartbeat.zipcode" validate={required()}/>
      <TextInput source="heartbeat.skus" validate={required()}/>
      <BooleanInput source="specialControl"/>
      <BooleanInput source="pbm"/>
    </SimpleForm>
  </Create>
);
