// in src/admin/index.tsx
import {Admin, Resource} from "react-admin";
import {fetchUtils} from 'react-admin';

import {stringify} from 'query-string';

import jsonServerProvider from "ra-data-json-server";
import {SellerList, SellerEdit, SellerCreate} from './sellers';


const {REACT_APP_API_URL: apiUrl = 'https://vtex-sellers-admin-api.qa.memed.rocks'} = process.env;
const dataProvider = jsonServerProvider(apiUrl);

/*
  refactor
*/
const resourceMap = {
  sellers: 'seller_id',
};

const httpClient = fetchUtils.fetchJson;

const addResourceId = (json, resource) => json.map((item) => {
  return {
    ...item,
    id: item[resourceMap[resource]]
  };
});

const customDataProvider = {
  ...dataProvider,
  getList: (resource, params) => {
    const {page, perPage} = params.pagination;
    const {field, order} = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({headers, json}) => ({
      data: addResourceId(json, resource),
      total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
    }));
  },

  create: (resource, params) => {
    const formData = new FormData()
    formData.append("file", params.data.file.rawFile)

    httpClient(`${apiUrl}/${resource}/${params.data.seller_id}/image`, {
      method: 'POST',
      body: formData
    })

    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({json}) => ({data: json}))
  },

  update: (resource, params) => {
    Object.entries(params.data).map((obj) => {
      if (obj[1] === '') delete params.data[obj[0]]
      return Object
    })

    Object.entries(params).map((obj) => {
      if (obj[1] === '') delete params[obj[0]]
      return obj
    })

    if (params?.data?.file?.rawFile) {
      const formData = new FormData()
      formData.append("file", params.data.file.rawFile)

      httpClient(`${apiUrl}/${resource}/${params.id}/image`, {
        method: 'POST',
        body: formData
      })
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({json}) => ({data: json}))
  },
};
/*
  refactor
*/


const App = () => (
  <Admin dataProvider={customDataProvider}>
    <Resource name="sellers" list={SellerList} edit={SellerEdit} create={SellerCreate}/>
  </Admin>
);

export default App;